var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('app-tour',{attrs:{"steps":_vm.steps}}),_c('b-alert',{staticClass:"cursor-pointer",attrs:{"variant":'primary',"show":""}},[_c('div',{staticClass:"alert-body"},[_c('strong',[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"BookOpenIcon"}}),_vm._v(" Every great system starts with a solid foundation. Create forms effortlessly to capture data and manage entries for seamless operations. ")],1)])]),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-badge',{staticClass:"badge-glow yo-guide button",attrs:{"variant":"danger"},on:{"click":_vm.tourStart}},[_c('feather-icon',{attrs:{"icon":"HelpCircleIcon"}}),_vm._v(" Guide ")],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',{attrs:{"id":"tour-badge-info"}},[_c('validation-observer',{ref:"yoBadgeRule",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" A ")]),_vm._v(" Badge Info ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Badge Info.")])])]),_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Badge","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Badge","label-for":"badge_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"badge_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.badgeName,"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"disabled":"true","label":"text","item-value":"value","item-text":"text"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var text = ref.text;
return [_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(text))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var text = ref.text;
return [_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(text))])]}}],null,true),model:{value:(_vm.form.badge_id),callback:function ($$v) {_vm.$set(_vm.form, "badge_id", $$v)},expression:"form.badge_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Kunjungan Ke Perusahaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kunjungan Ke Perusahaan","label-for":"visitor_cat_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"visitor_cat_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryName,"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","item-value":"value","item-text":"text"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('b-spinner',{attrs:{"small":"","type":"grow","variant":color}}),_c('span',{staticClass:"ml-50 align-middle"},[_c('strong',[_vm._v(_vm._s(text))])])]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('b-spinner',{attrs:{"small":"","type":"grow","variant":color}}),_c('span',{staticClass:"ml-50 align-middle"},[_c('strong',[_vm._v(_vm._s(text))])])]}}],null,true),model:{value:(_vm.form.visitor_cat_id),callback:function ($$v) {_vm.$set(_vm.form, "visitor_cat_id", $$v)},expression:"form.visitor_cat_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nomor Whatsapp * ( Ex: 8155 7777 33 )","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Nomor Whatsapp *","vid":"phone","rules":{
                    required: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" ID (+62) ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"8155 7777 33","state":errors.length > 0 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),(false)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-card',[_c('validation-observer',{ref:"yoSocialLinksRule",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" B ")]),_vm._v(" Social Links "),_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" Opsional ")])],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Social Links Opsional")])])]),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Twitter","label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"twitter","rules":"twitter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"TwitterIcon"}})],1),_c('b-form-input',{attrs:{"id":"twitter","type":"twitter","state":errors.length > 0 ? false : null,"placeholder":"salpalaran"},model:{value:(_vm.form.twitter),callback:function ($$v) {_vm.$set(_vm.form, "twitter", $$v)},expression:"form.twitter"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3873731328)})],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Facebook","label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"facebook","rules":"facebook"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FacebookIcon"}})],1),_c('b-form-input',{attrs:{"id":"facebook","type":"facebook","state":errors.length > 0 ? false : null,"placeholder":"salpalaran"},model:{value:(_vm.form.facebook),callback:function ($$v) {_vm.$set(_vm.form, "facebook", $$v)},expression:"form.facebook"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1864399072)})],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Instagram","label-for":"instagram"}},[_c('validation-provider',{attrs:{"name":"instagram","rules":"instagram"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"InstagramIcon"}})],1),_c('b-form-input',{attrs:{"id":"instagram","type":"instagram","state":errors.length > 0 ? false : null,"placeholder":"salpalaran"},model:{value:(_vm.form.instagram),callback:function ($$v) {_vm.$set(_vm.form, "instagram", $$v)},expression:"form.instagram"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1116898112)})],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Linkedin","label-for":"linkedin"}},[_c('validation-provider',{attrs:{"name":"linkedin","rules":"linkedin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LinkedinIcon"}})],1),_c('b-form-input',{attrs:{"id":"linkedin","type":"linkedin","state":errors.length > 0 ? false : null,"placeholder":"salpalaran"},model:{value:(_vm.form.linkedin),callback:function ($$v) {_vm.$set(_vm.form, "linkedin", $$v)},expression:"form.linkedin"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3125552032)})],1)],1)],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-card',{attrs:{"id":"tour-personal-info"}},[_c('validation-observer',{ref:"yoPersonalInfoRule",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" B ")]),_vm._v(" Personal Info ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Personal Info.")])])]),_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-card',{attrs:{"border-variant":"primary","header-border-variant":"primary","header-text-variant":"primary"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('h4',{staticClass:"mb-1"},[_vm._v("Document KTP")]),_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.no_ktp_file,"height":"110","width":"170"}}),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"","label-for":"vi-schedule-no_ktp_file"}},[_c('validation-provider',{attrs:{"name":"File KTP","vid":"File KTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"id":"form-ktp-file","placeholder":"Choose a file KTP or drop it here...","accept":".jpeg, .jpg, .png","state":errors.length > 0 ? false : null},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.form.no_ktp_file),callback:function ($$v) {_vm.$set(_vm.form, "no_ktp_file", $$v)},expression:"form.no_ktp_file"}})]}}])})],1),(_vm.text)?_c('div',[_c('b-card',{attrs:{"border-variant":"success","header-border-variant":"success","header-text-variant":"success"}},[_c('small',[_vm._v("System berhasil membaca "),_c('strong',[_vm._v("Nomor NIK : ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer",attrs:{"title":"Click to copy"},on:{"click":function($event){return _vm.copyText(_vm.text)}}},[_vm._v(_vm._s(_vm.text)+" "),_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1),_vm._v(" , jika benar maka Click nomor NIK (copy) dan paste ke kolom pengisian "),_c('strong',[_vm._v("Nomor KTP")]),_vm._v(".")])])],1):_vm._e()],1),_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nomor KTP *","label-for":"no_ktp"}},[_c('validation-provider',{attrs:{"name":"Nomor KTP *","rules":{
                              required: true,
                              min: 16
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1),_c('cleave',{staticClass:"form-control",attrs:{"id":"no_ktp","raw":true,"options":_vm.options.noKTP,"placeholder":"Nomor KTP"},model:{value:(_vm.form.no_ktp),callback:function ($$v) {_vm.$set(_vm.form, "no_ktp", $$v)},expression:"form.no_ktp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap *","label-for":"full_name"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap *","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"full_name","state":errors.length > 0 ? false : null,"placeholder":"Nama Lengkap"},model:{value:(_vm.form.full_name),callback:function ($$v) {_vm.$set(_vm.form, "full_name", $$v)},expression:"form.full_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email ( Opsional )","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"email@sal.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('strong',[_c('i',[_vm._v("Sudah pernah memiliki QRCode ?")]),_c('span',{staticStyle:{"cursor":"pointer","color":"blue"},attrs:{"id":"tour-qrcode"},on:{"click":function($event){return _vm.$router.push({ name: 'reuse-qrcode' })}}},[_vm._v(" Click Reuse QRCode ")])])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-card',[_c('validation-observer',{ref:"yoScheduleRule",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" C ")]),_vm._v(" Schedule IN ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Planning.")])])]),_c('b-col',[_c('b-form-group',{attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Schedule IN Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-calendar',{attrs:{"block":"","locale":"id-ID"},model:{value:(_vm.form.schedule_in_date),callback:function ($$v) {_vm.$set(_vm.form, "schedule_in_date", $$v)},expression:"form.schedule_in_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Schedule IN Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mt-2",attrs:{"now-button":"","reset-button":"","locale":"id-ID","hour12":false},model:{value:(_vm.form.schedule_in_time),callback:function ($$v) {_vm.$set(_vm.form, "schedule_in_time", $$v)},expression:"form.schedule_in_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('strong',[_c('br'),_c('i',[_vm._v("\" Every scan tells a story of protection and control. \"")])])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',[_c('validation-observer',{ref:"yoScheduleOthers",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" E ")]),_vm._v(" Others ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Descriptions / Others.")])])]),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Alamat *","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Alamat *","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapIcon"}})],1),_c('b-form-input',{attrs:{"id":"address","type":"address","state":errors.length > 0 ? false : null,"placeholder":"C5QH+98X, Rw., Rw. Makmur, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Asal Perusahaan / Instansi","label-for":"from_company"}},[_c('validation-provider',{attrs:{"name":"Asal Perusahaan / Instansi *","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"from_company","type":"from_company","state":errors.length > 0 ? false : null,"placeholder":"PT SARANA ABADI LESTARI"},model:{value:(_vm.form.from_company),callback:function ($$v) {_vm.$set(_vm.form, "from_company", $$v)},expression:"form.from_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ket. / Keperluan *","label-for":"ket"}},[_c('validation-provider',{attrs:{"name":"Ket. / Keperluan *","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CoffeeIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ket","type":"ket","state":errors.length > 0 ? false : null,"placeholder":"Example: Monthly Meeting K3A, Daily Checking, etc"},model:{value:(_vm.form.ket),callback:function ($$v) {_vm.$set(_vm.form, "ket", $$v)},expression:"form.ket"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"id":"tour-simpan","disabled":_vm.loadingSaveData,"block":"","variant":"primary"},on:{"click":_vm.formSubmitted}},[(_vm.loadingSaveData)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan data dan Kirim QRCode Ke Whatsapp "+_vm._s(_vm.form.phone ? ("+62 " + (_vm.form.phone)) : "")+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }